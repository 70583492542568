import React from 'react';
import {Typography} from "@mui/material";
import Footer1 from "./components/footer1";
import AboutUsBlock1 from "./about-us-block1";
import './main-page.css';

function MainPage() {


  return (
      <div style={{
        backgroundImage: `url("kyiv_logo_1.jpg")`,
        backgroundPosition: 'center',
        marginTop: 5,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '120vh'
      }}>
        <div className="head1" style={ {textAlign: 'center', padding: '2vw 3vw 1vw 3vw'} }>
          <Typography style={ {fontSize: '2vw',marginTop: 5, marginBottom: 15, color: 'whitesmoke'} }>
            Пропонуємо Вашій увазі
          </Typography>
          <Typography style={ {fontSize: '2vw',marginBottom: 15,color: 'whitesmoke'} }>
            пасажирські перевезення за напрямком
          </Typography>
          <Typography  style={ {fontSize: '2vw',color: 'whitesmoke',marginBottom: 30 } }>
            Кропивницький - Київ - Кропивницький
          </Typography>
          <Typography style={ {fontSize: '1.5vw',color: '#ffd54f', fontStyle: 'italic'} }>
            Можливі перевезення у будь-яке місто України!
          </Typography>
        </div>
        <div className="head2" style={ {textAlign: 'center', padding: '2vw 3vw 1vw 3vw' } }>
          <Typography style={ {fontSize: '5vw',marginTop: 3, marginBottom: 10, color: 'whitesmoke'} }>
            Пропонуємо Вашій увазі
          </Typography>
          <Typography style={ {fontSize: '4vw',marginBottom: 5,color: 'whitesmoke'} }>
            пасажирські перевезення за напрямком
          </Typography>
          <Typography  style={ {fontSize: '4.5vw',color: 'whitesmoke',marginBottom: 10 } }>
            Кропивницький - Київ - Кропивницький
          </Typography>
          <Typography style={ {fontSize: '3vw',color: '#ffd54f', fontStyle: 'italic'} }>
            Можливі перевезення у будь-яке місто України!
          </Typography>
        </div>
        <div style={ { padding: '5vh 1vw 5vh 1vw' } }>
          <Footer1/>
        </div>
        <div style={ { padding: '5vh 1vw 5vh 1vw' } }>
          <AboutUsBlock1/>
        </div>
      </div>
  );
}

export default MainPage;
