import React from 'react';
import {Avatar, Typography} from "@mui/material";
import './serviceBlock2.css';
import StarIcon from "@mui/icons-material/Star";

function ServiceBlock2() {


  return (
      <div className="wrapper3">
        <div className="box3">
            <Avatar sx={{
              bgcolor: '#ffd54f',
              width: '6.5vw',
              height: '6.5vw',
              maxHeight: '40px',
              maxWidth: '40px',
              color: 'black',
              border: '2px solid #ffd54f',
              marginTop: '5%', marginBottom: '5%'
            }}>
              <StarIcon/>
            </Avatar>
          <Typography style={{fontSize: '2vw', fontWeight: 'bold', color: '#ffd54f'}}>
            Будь-яке місто України
          </Typography>
          <Typography style={{fontSize: '2vw', color: 'white', margin: '1vh 1vw 0.5vh 1vw'}}>
            У Вас є можливість замовити поїздку до будь-якого міста України.
          </Typography>
        </div>
          <img src="/bussines.jpg" width={'100%'} alt="logo"/>
      </div>
  );
}

export default ServiceBlock2;