import React from 'react';
import {Typography} from "@mui/material";
import ScheduleBlock from "./components/schedule-block";
import ServiceBlock2 from "./service-block2";
import ServiceBlock1 from "./service-block1";

function ServicePage() {


  return (
      <div style={{
        backgroundImage: `url("kyiv_logo_1.jpg")`,
        backgroundPosition: 'center',
        marginTop: 5,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '200vh'
      }}>

        <div style={{textAlign: 'center', padding: '5%'}}>
          <Typography style={{marginTop: 20, color: 'white', fontSize: '4vw', fontWeight: 'bold'}}>
            Послуги та графік
          </Typography>

          <Typography style={{marginTop: 20,fontSize: '3vw', color: 'whitesmoke', fontStyle: 'italic'}}>
            Надійні перевезення з чітким графіком – ваш комфорт у наших руках!
          </Typography>
        </div>
        <div style={{padding: '5vh 1vw 5vh 1vw'}}>
          <ServiceBlock1/>
        </div>

        <div style={{padding: '5vh 1vw 5vh 1vw'}}>
          <ServiceBlock2/>
        </div>
        <div>
          <ScheduleBlock/>
        </div>
      </div>
  );
}

export default ServicePage;
