import React from 'react';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import StarIcon from '@mui/icons-material/Star';
import {Avatar, Typography} from "@mui/material";
import '../footer1.css';

function Footer1() {


  return (
      <div className="wrapper">
        <div className="box1">
          <Avatar sx={{
            bgcolor: '#ffd54f',
            color: 'black',
            border: '2px solid black',
            marginTop: '3vh', marginBottom: '10%'
          }}>
            <AirportShuttleIcon/>
          </Avatar>
        </div>
        <div className="box1">
          <Avatar sx={{
          bgcolor: '#ffd54f',
          color: 'black',
          border: '2px solid black',
          marginTop: '3vh'
        }}>
          <FlightTakeoffIcon/>
        </Avatar>
        </div>
        <div className="box1">
          <Avatar sx={{
          bgcolor: '#ffd54f',
          color: 'black',
          border: '2px solid black',
          marginTop: '3vh'
        }}>
          <StarIcon/>
        </Avatar>
        </div>
        <div className="box21">
          <Typography style={{fontSize: '1.4vw', fontWeight: 'bold', margin: '5% 5% 3% 5%'}}>
            В Кропивницькому забираємо Вас за адресою
          </Typography>
        </div>
        <div className="box21">
          <Typography style={{fontSize: '1.4vw', fontWeight: 'bold', margin: '5% 5% 3% 5%'}}>
            Трансфер до аеропортів та ж/д вокзалу
          </Typography>
        </div>
        <div className="box21">
          <Typography  style={{fontSize: '1.4vw', fontWeight: 'bold', margin: '5% 5% 3% 5%'}}>
            Кінцева зупинка ст.м. "Видубичі"
          </Typography>
        </div>

        <div className="box2">
          <Typography style={{fontSize: '2.7vw', fontWeight: 'bold', margin: '5% 5% 3% 5%'}}>
            В Кропивницькому забираємо Вас за адресою
        </Typography>
        </div>
        <div className="box2">
          <Typography style={{fontSize: '2.7vw', fontWeight: 'bold', margin: '5% 5% 3% 5%'}}>
          Трансфер до аеропортів та ж/д вокзалу
        </Typography>
        </div>
        <div className="box2">
          <Typography  style={{fontSize: '2.7vw', fontWeight: 'bold', margin: '5% 5% 3% 5%'}}>
          Кінцева зупинка станція метро "Видубичі"
        </Typography>
        </div>
        {/*---------------------------------------------------------------------------*/}
        <div>
          <Typography className="box4" style={{fontSize: '2.7vw', margin: '5% 5% 3% 5%'}}>
          Ви можете замовити поїздку з будь-якої бажаної адреси в Кропивницькому і назад.
        </Typography>
        </div>
        <div>
          <Typography className="box4" style={{fontSize: '2.7vw', margin: '5% 5% 3% 5%'}}>
          Доставимо Вас до аеропортів України "Бориспіль"/"Жуляни" та залізничного вокзалу
        </Typography>
        </div>
        <div>
          <Typography className="box4" style={{fontSize: '2.7vw', margin: '5% 5% 3% 5%'}}>
          Доставимо Вас до кінцевої зупинки "Станція метро Видубичі" у Києві
        </Typography>
        </div>

        <div>
          <Typography className="box41" style={{fontSize: '1.4vw', margin: '5% 5% 3% 5%'}}>
            Ви можете замовити поїздку з будь-якої бажаної адреси в Кропивницькому і назад.
          </Typography>
        </div>
        <div>
          <Typography className="box41" style={{fontSize: '1.4vw', margin: '5% 5% 3% 5%'}}>
            Доставимо Вас до аеропортів України "Бориспіль"/"Жуляни" та залізничного вокзалу
          </Typography>
        </div>
        <div>
          <Typography className="box41" style={{fontSize: '1.4vw', margin: '5% 5% 3% 5%'}}>
            Доставимо Вас до кінцевої зупинки "Станція метро Видубичі" у Києві
          </Typography>
        </div>
      </div>
  );
}

export default Footer1;